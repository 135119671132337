import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout/layout.js'
import SEO from '../components/parts/seo.js'
import Icon from '../components/parts/icon.js'

import '../scss/privacy.scss'

const Privacy = () => {
  const pageTitle = {
    mainTitle: {
      text: 'プライバシーポリシー'
    }
  }

  return (
    <React.Fragment>
      <SEO
        title='プライバシーポリシー'
        description=''
        pathname='/privacy/'
        article
      />
      <Layout pageTitle={pageTitle} className="article privacy">
        <section className="article__content">
          <p>
            本プライバシーポリシーは、<span lang="en" className="upper">UWeb</span>（<Link to="/">https://u-web-nana.com/</Link>）（以下、「当サイト」とします。）の各種サービス（当サイトによる情報提供、各種お問い合わせの受付等）において、当サイトの訪問者（以下、「訪問者」とします。）の個人情報もしくはそれに準ずる情報を取り扱う際に、当サイトが遵守する方針を示したものです。
          </p>
          <h2>基本方針</h2>
          <p>
            当サイトは、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考えております。<br />
            個人情報に関する法令を遵守し、当サイトで取扱う個人情報の取得、利用、管理を適正に行います。当サイトで収集した情報は、利用目的の範囲内で適切に取り扱います。
          </p>
          <h2>適用範囲</h2>
          <p>
            本プライバシーポリシーは、当サイトにおいてのみ適用されます。
          </p>
          <h2>個人情報の取得と利用目的</h2>
          <p>
            当サイトで取得する訪問者の個人情報と利用目的、保存期間等は下記の通りです。
          </p>
          <h3>お問い合せされた個人情報を取得します</h3>
          <p>
            当サイトではお問い合わせフォーム（<Link to="/contact/">https://u-web-nana.com/contact/</Link>）を設けています。
          </p>
          <p>
            訪問者がそのお問い合わせフォームから問い合わせをされた際に入力された、以下の個人情報を取得します。
          </p>
          <ul>
            <li>お問い合わせフォームに入力された名前</li>
            <li>お問い合わせフォームに入力されたメールアドレス</li>
            <li>お問い合わせフォームに入力されたお問い合わせ内容</li>
          </ul>
          <h4>利用目的について</h4>
          <p>
            お問い合わせ対応をするためと、訪問者の管理のためです。訪問者からのお問い合わせ情報を保存しておくことによって、同じ訪問者が別のお問い合わせをした際に、過去の問い合わせ内容を踏まえた対応をすることが出来、より的確な対応をすることができます。
          </p>
          <p>
            また、当サイト内で「このようなお問い合わせがありました」と紹介する場合がございます。
          </p>
          <h4>保存期間について</h4>
          <p>
            お問い合わせフォームに入力された個人情報は、当サイトが存続する限り保存します。
          </p>
          <h4>個人情報取得の同意について</h4>
          <p>
            当サイトでは、お問い合わせフォームからお問い合わせをする前に、当プライバシーポリシーをご一読いただくよう案内しています。
          </p>
          <p>
            お問い合わせをされた時点で、その訪問者は当プライバシーポリシーに同意されたとみなします。
          </p>
          <h3><span lang="en">Cookie</span>による個人情報の取得</h3>
          <p>
            当サイトは、訪問者のコンピュータに<span lang="en">Cookie</span>を送信することがあります。
          </p>
          <p>
            <span lang="en">Cookie</span>（クッキー）とは、ウェブサイトを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、訪問者のコンピュータにファイルとして保存しておく仕組みです。
          </p>
          <h4>利用目的について</h4>
          <p>
            訪問者の当サイト閲覧時の利便性を高めるためです。
          </p>
          <p>
            たとえば、次回同じページにアクセスすると<span lang="en">Cookie</span>の情報を使って、ページの運営者は訪問者ごとに表示を変えることができます。
          </p>
          <p>
            たとえば、あるサイトを利用していて、初回はログインパスワードを入力する画面が表示されたけど、2回目以降はログイン画面は表示されずにアクセスできた、という経験ありませんか。それは<span lang="en">Cookie</span>によるものです。
          </p>
          <p>
            訪問者がブラウザの設定で<span lang="en">Cookie</span>の送受信を許可している場合、ウェブサイトは、訪問者のブラウザから<span lang="en">Cookie</span>キーを取得できます。
          </p>
          <p>
            なお、訪問者のブラウザはプライバシー保護のため、そのウェブサイトのサーバーが送受信した<span lang="en">Cookie</span>のみを送信します。
          </p>
          <h4>アクセス解析ツールについて</h4>
          <p>
            当サイトでは、<span lang="en">Google</span>によるアクセス解析ツール「<span lang="en">Google</span>アナリティクス」を利用しています。
          </p>
          <p>
            この<span lang="en">Google</span>アナリティクスはアクセス情報の収集のために<span lang="en">Cookie</span>を使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。
          </p>
          <p>
            <span lang="en">Google</span>アナリティクスの<span lang="en">Cookie</span>は、26ヶ月間保持されます。この機能は<span lang="en">Cookie</span>を無効にすることで収集を拒否することができますので、お使いのブラウザの設定をご確認ください。
          </p>
          <p>
            <span lang="en">Google</span>アナリティクスの利用規約に関しては、<a target="_blank" rel="noopener noreferrer" href="https://marketingplatform.google.com/about/analytics/terms/jp/">こちら <Icon type="open_in_new" /></a>から確認できます。<br />
            また、「ユーザーが<span lang="en">Google</span>パートナーのサイトやアプリを使用する際の<span lang="en">Google</span>によるデータ使用」に関しては、<a target="_blank" rel="noopener noreferrer" href="https://policies.google.com/technologies/partner-sites?hl=ja">こちら <Icon type="open_in_new" /></a>から確認できます。
          </p>
          <h4>当サイトに掲載されている広告について</h4>
          <p>
            当サイトでは、Googleなどをはじめとする第三者配信事業者が<span lang="en">Cookie</span>を利用して、ユーザーがWebサイトや他のWebサイトに過去にアクセスした際の情報に基づき、広告を配信する場合があります。<br />
            第三者配信事業者は、<span lang="en">Cookie</span>を利用することにより、ユーザーがWebサイトや他のWebサイトに過去にアクセスした際の情報に基づき、適切な広告をユーザーに表示できます。<br />
          </p>
          <p>
            ユーザーは、広告設定でパーソナライズ広告を無効にできるほか、<a target="_blank" rel="noopener noreferrer" href="www.aboutads.info">www.aboutads.info <Icon type="open_in_new" /></a>にアクセスすることで、パーソナライズ広告に使われる第三者配信事業者の<span lang="en">Cookie</span>を無効にできます。
          </p>
          <p>
            当該第三者によって取得された<span lang="en">Cookie</span>情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。
          </p>
          {/* <h5>第三者への<span lang="en">Cookie</span>情報等の広告配信の利用停止について</h5>
          <p>
            訪問者は、当該第三者のウェブサイト内に設けられたオプトアウト（個人情報を第三者に提供することを停止すること）ページにアクセスして、当該第三者による<span lang="en">Cookie</span>情報等の広告配信への利用を停止することができます。
          </p> */}
          <h4><span lang="en">Cookie</span>情報の送受信の許可・拒否について</h4>
          <p>
            訪問者は、<span lang="en">Cookie</span>の送受信に関する設定を「すべての<span lang="en">Cookie</span>を許可する」、「すべての<span lang="en">Cookie</span>を拒否する」、「<span lang="en">Cookie</span>を受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。<span lang="en">Cookie</span>に関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。
          </p>
          <p>
            すべての<span lang="en">Cookie</span>を拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
          </p>
          <h3>個人情報の管理</h3>
          <p>
            当サイトは、訪問者からご提供いただいた情報の管理について、以下を徹底します。
          </p>
          <h4>情報の正確性の確保</h4>
          <p>
            訪問者からご提供いただいた情報については、常に正確かつ最新の情報となるよう努めます。
          </p>
          <h4>安全管理措置</h4>
          <p>
            当サイトは、個人情報の漏えいや滅失又は棄損を防止するために、適切なセキリュティ対策を実施して個人情報を保護します。
          </p>
          <h4>個人情報の廃棄</h4>
          <p>
            個人情報が不要となった場合には、すみやかに廃棄します。
          </p>
          <h4>個人情報の開示、訂正、追加、削除、利用停止</h4>
          <p>
            訪問者ご本人からの個人情報の開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認した上、速やかに対応いたします。
          </p>
          <p>
            上記を希望される場合は、<Link to="/contact/">お問い合わせフォーム</Link>よりお問い合わせください。
          </p>
          <h3>個人情報の第三者への提供について</h3>
          <p>
            当サイトは、訪問者からご提供いただいた個人情報を、訪問者本人の同意を得ることなく第三者に提供することはありません。また、今後第三者提供を行うことになった場合には、提供する情報と提供目的などを提示し、訪問者から同意を得た場合のみ第三者提供を行います。
          </p>
          <h3>未成年の個人情報について</h3>
          <p>
            未成年者がお問い合わせフォームから問い合わせをされたりする場合は必ず親権者の同意を得るものとし、お問い合わせをされた時点で、当プライバシーポリシーに対して親権者の同意があるものとみなします。
          </p>
          <h2>お問い合わせ先</h2>
          <p>
            当サイト、又は個人情報の取り扱いに関しては、当サイトの<Link to="/contact/">お問い合わせフォーム</Link>からお問い合わせください。
          </p>
          <h2>プライバシーポリシーの変更について</h2>
          <p>
            当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本プライバシーポリシーの内容を適宜見直しその改善に努めます。修正された最新のプライバシーポリシーは常に本ページにて開示されます。
          </p>
          <div className="privacy__date">
            <time datatime="2019-02-08">2019年02月08日</time>　策定<br />
            <time datatime="2021-01-21">2021年01月21日</time>　改訂<br />
            <span lang="en" className="upper">UWeb</span> やまぐちなな
          </div>
        </section>
      </Layout>
    </React.Fragment>
  )
}
export default Privacy;
